// auth routes
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const PROFILE = "/profile";
export const REQUEST_FORGOT_PASSWORD = "/request-forgot-password";
export const FORGOT_PASSWORD = "/forgot-password/:accessToken";
export const PASSWORD_FORGET = "/pw-forget";
export const PRIVACY = "/privacy-policy";
export const ADD_TO_HOMESCREEN = "/add-to-homescreen";

// game routes
export const GAME = "/game";
export const PLAY_GAME = "/game/play";
export const SUBMIT_QUESTION = "/submit-question";
export const LEADERBOARD = "/leaderboard";
export const ABOUT = "/about";
export const USER_PROFILES = "/profile/:userId";
export const CONTEST = "/contest";

// admn routes
export const ADMIN_DASHBOARD = "/admin";
export const ADMIN_QUESTIONS = "/admin/questions";
export const REVIEW = "/admin/review-submitted-questions";
export const REVIEW_CONTEST = "/admin/review-contested-questions";
export const CREATE_NEW_QUIZ = "/admin/create-new-quiz";
export const MANAGE_QUIZZES = "/admin/manage-quizzes";
export const MANAGE_USERS = "/admin/manage-users";
export const ADMIN_LEADERBOARD = "/admin/leaderboard";
