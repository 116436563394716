/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axiosInstance";

export const createStreak = createAsyncThunk(
  "streak/createStreak",
  async (answeredQuestions) => {
    const { data } = await axios.post(`/api/streaks`, answeredQuestions);

    return data;
  }
);

export const getMaxStreakForUser = createAsyncThunk(
  "streak/getMaxStreakForUser",
  async () => {
    const { data } = await axios.get(`/api/streaks/`);
    return data;
  }
);

const INIT_STATE = {
  streak: [],
  streakEnded: false,
  isAnswering: true,
  answeredCorrectly: undefined,
  midAuthentication: false,
  maxStreak: 0,
  isLoading: false,
  hasError: false,
  hasLifelineOption: false,
};
//Slice
/////////////////////////////////////////////////////////////
const streakSlice = createSlice({
  name: "streak",
  initialState: INIT_STATE,
  reducers: {
    resetQuiz(state, action) {
      return {
        ...INIT_STATE,
        maxStreak: Math.max(
          state.streak.filter((it) => it.answeredCorrectly).length,
          state.maxStreak
        ),
      };
    },
    setMaxScore(state, action) {
      return { ...state, maxStreak: action.payload || 0 };
    },
    setMidAuth(state, action) {
      return { ...state, midAuthentication: true };
    },
    setIsAnswering(state, action) {
      // streak has ended if:
      // - user answers the question incorrectly
      // user sees lifeline screen if:
      // - user has hootpoints available
      // - user had a streak > 0

      return {
        ...state,
        isAnswering: true,
        streakEnded: !state.answeredCorrectly,
        // set this value here and then reset it when the user selects a lifeline option
        hasLifelineOption:
          action.payload > 0 &&
          state.streak.filter((it) => it.answeredCorrectly).length > 0,
      };
    },
    shouldEndStreak(state, action) {
      // end streak or return to gameplay and reset lifeline option
      return {
        ...state,
        streakEnded: action.payload, // if the user has used a lifeline
        hasLifelineOption: false,
      };
    },
    addToStreak(state, action) {
      const { questionId, answeredCorrectly } = action.payload;
      return {
        ...state,
        isAnswering: false,
        answeredCorrectly,
        streak: [...state.streak, { questionId, answeredCorrectly }],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStreak.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(createStreak.fulfilled, (state, action) => {
        state.streakEnded = false;
        state.answeredCorrectly = undefined;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(createStreak.rejected, (state, action) => {
        state = INIT_STATE;
      })
      .addCase(getMaxStreakForUser.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
      })
      .addCase(getMaxStreakForUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getMaxStreakForUser.fulfilled, (state, action) => {
        state.maxStreak = action.payload;
        state.hasError = false;
        state.isLoading = false;
      });
  },
});

//Actions
/////////////////////////////////////////////////////////////
export const {
  setNew,
  setMaxScore,
  addToStreak,
  setIsAnswering,
  resetQuiz,
  setMidAuth,
  shouldEndStreak,
} = streakSlice.actions;

//Reducer
/////////////////////////////////////////////////////////////
export default streakSlice.reducer;

//Selectors
/////////////////////////////////////////////////////////////
export const selectQuestion = (state) => state.streak;
